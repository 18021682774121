<style>
    .mouse-cta-dash:hover {
        border: 1px solid var(--color-broker);
    }
</style>

<template>
    <div class="col-12 px-0">           
         <div class="container-fluid">
        <div class="col-12">
            <div class="row" >
                <div class="col-12 col-md-12 col-lg-9" >
                    <div class="row">
                        <div class="col-12">
                            <div class="row" >
                                <!-- <div class="col-12 col-md-8 col-lg-8 p-0 align-self-center">
                                    <form>
                                        <div class="d-flex px-3 w-100">
                                            <input class="form-control border-0" type="search" placeholder="Procurar" aria-label="Search" style="border-bottom: 1px #E3E3E3 solid !important; height: 44px; background-color: transparent; border-radius:0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search form-control-icon align-self-center" style="top: 11px; right: 29px;"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        </div>
                                    </form>
                                </div> -->
                                <!-- <div class="col-12 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label>Selecione o empreendimento</label>
                                            <select class="form-control selectsearch" id="searchselect" style="height: 48px !important" v-model="enterprise_id">
                                                <option v-for="item in all_enterprises" :value="item.id">{{item.name}}</option>
                                            </select>                                                     
                                        </div>
                                </div>  -->
                            </div>
                        </div>
                        <div class="col-12" >
                            <div class="col-12 text-right pt-2">
                                <h2 class="mb-1 font-weight-medium title-dash-ar" style="color: var(--color-broker);">Material para Download</h2>
                            </div>
                            <div class="row">
                                <div class="d-flex p-2 mt-2 justify-content-center w-100 mb-2 ">
                                    <div class="col-12">
                                        <div v-if="enterprise.image !=null"  class="bg-white d-block d-lg-none  bder-default p-4 mouse-cta-dash" >
                                        <div class="col-12 align-self-center p-0">
                                                <div class="col-12 p-2 w-100 mx-auto">
                                                    <img :src="enterprise.image"  height="45" class="mx-auto d-flex" alt="Qsuíte" style="border-top-right-radius: 25px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8 col-md-12 col-lg-4 pr-auto pr-sm-0 pt-2 d-none d-lg-block">
                                    <div class="col-12 p-2 p-sm-0">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 align-self-center p-0">
                                                    <div class="col-12 p-0">
                                                        <img :src="enterprise.image_principal" class="img-fluid"  alt="Qsuíte" style="border-top-right-radius: 25px;    height: 420px;object-fit: cover; width:100%">
                                                    </div>
                                                </div>
                                                <div class="col-12 align-self-center px-0 pb-2">
                                                    <div class="bg-white p-4 mouse-cta-dash" style="border-bottom-right-radius: 16px; border-bottom-left-radius: 16px;">
                                                        <a  href="#">
                                                            <div class="col-12 text-center pb-2">
                                                                <img :src="enterprise.image" class="" v-if="enterprise.image!=null" height="30" alt="Qsuíte">
                                                                   <h5  v-if="enterprise.image==null" class="mb-0 title-dash-ar-inc"><b>{{enterprise.name}}</b></h5>
                                                            </div>
                                                            <!-- <div class="d-flex mx-auto" style="max-width:max-content;">
                                                                <div class="align-self-center">
                                                                    <img src="@/assets/img/ico-qs-01.png" class="" height="18" alt="Qsuíte">
                                                                </div>
                                                                <div class="align-self-center pl-2">
                                                                    <h2 class="font-12 m-0">
                                                                        Meu link exclusivo
                                                                    </h2>
                                                                </div>
                                                            </div> -->
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div class="col-12 col-md-12 col-lg-8" >
                                    <div class="col-12">
                                    <!-- <div class="col-12" v-if="carregandoarquivo=='não'"> -->
                                        <div class="row">
                                          
                                            <div class="col-6 col-md-4 col-lg-4 p-2 p-sm-2" @click="showModal(1)">
                                                <div  style="min-height: 176px;"  class="bg-white bder-default pt-4 pb-4 pl-3 pr-3 mouse-cta-dash" >
                                                    <div style="cursor:pointer">
                                                        <div>
                                                            <div class="mb-1 mb-sm-3">
                                                                <img src="@/assets/img/ico-qs-02.png" class="" height="28" alt="Qsuíte">
                                                            </div>
                                                            <div>
                                                                <br class="d-none d-md-block">
                                                                <h2 class="mb-1 font-weight-medium p-0 title-dash-cliente" style="min-height: 66px;">
                                                                    Tabela<br>
                                                                    Comercial do Mês
                                                                </h2>
                                                            </div>
                                                            <div class="text-right" style="display: flex; justify-content: space-between;">
                                                                <div class="top-0 font-weight-medium" style="width: fit-content; height: fit-content; font: bold; color:#949494">
                                                                  arquivos: {{ getDocumentQuantityByName('Tabela comercial do mês') }}
                                                                </div>
                                                                <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 col-lg-4 p-2 p-sm-2" @click="showModal(2)">
                                                <div class="bg-white bder-default p-4 mouse-cta-dash" style="min-height: 176px;">                                                   
                                                    <div style="cursor:pointer">
                                                        <div class="mb-1 mb-sm-3">
                                                            <img src="@/assets/img/ico-qs-01-n.png" class="" height="28" alt="Qsuíte">
                                                        </div>
                                                        <div>
                                                            <br class="d-none d-md-block">
                                                            <h2 class="mb-1 font-weight-medium p-0 title-dash-cliente" style="min-height: 66px;">
                                                                Kit<br>
                                                                Marketing
                                                            </h2>
                                                        </div>
                                                        <div class="text-right mt-2" style="display: flex; justify-content: space-between;">
                                                          <div class="top-0 font-weight-medium" style="width: fit-content; height: fit-content; font: bold; color:#949494">
                                                            arquivos: {{ getDocumentQuantityByName('Kit Marketing') }}
                                                          </div>

                                                          <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                        </div>
                                                    </div>                                                  
                                                </div>
                                            </div>
                                            <div  class="col-6 col-md-4 col-lg-4 p-2 p-sm-2 w-100">
                                                <div class="w-100" @click="showModal(3)" v-if="carregando_imagens=='não'">
                                                    <div style="min-height: 176px;" class="bg-white bder-default p-4 mouse-cta-dash">
                                                        <a data-toggle="modal" data-target="#right-modal" href="#">
                                                            <div>
                                                                <div class="mb-1 mb-sm-3">
                                                                    <img src="@/assets/img/ico-qs-02-n.png" class="" height="28" alt="Qsuíte">
                                                                </div>
                                                                <div>
                                                                    <br class="d-none d-md-block">
                                                                    <h2 class="mb-1 font-weight-medium p-0 title-dash-cliente" style="min-height: 66px;">
                                                                        Imagens
                                                                    </h2>
                                                                </div> 
                                                                <div class="text-right pb-1" style="display: flex; justify-content: space-between;">
                                                                    <div class="top-0 font-weight-medium" style="width: fit-content; height: fit-content; font: bold; color:#949494">
                                                                      arquivos: {{ getDocumentQuantityByName('Imagens e plantas') }}
                                                                    </div>
                                                                    <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>

                                                 <div  class="w-100" v-else>
                                                    <div class="w-100">
                                                        <div style="min-height: 176px;" class="bg-white bder-default p-4 mouse-cta-dash">
                                                            <div style="cursor:pointer">
                                                                <div>
                                                                    <div class="mb-1 mb-sm-3">
                                                                        <img src="@/assets/img/ico-qs-02-n.png" class="" height="28" alt="Qsuíte">
                                                                    </div>
                                                                    <div>
                                                                        <br class="d-none d-md-block">
                                                                        <h2 class="mb-1 font-weight-medium p-0 title-dash-cliente" style="min-height: 66px;">
                                                                        Carregando...
                                                                        </h2>
                                                                    </div> 
                                                                        <div class="text-right pb-1">
                                                                        <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                                    </div>                                                       
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                            
<!--                                                
                                                   <div class="col-12 text-center pb-2">
                                                                <img :src="enterprise.image" class="" height="30" alt="Qsuíte">
                                                            </div> -->
                                            
                                                
                                            </div>
                                            </div>
                                        
                                            <div class="col-6 col-md-4 col-lg-4 p-2 p-sm-2" @click="showModal(4)">
                                                <div  style="min-height: 176px;"  class="bg-white bder-default p-4 mouse-cta-dash">
                                                    <div style="cursor:pointer">
                                                        <div>
                                                            <div class="mb-1 mb-sm-3">
                                                                <img src="@/assets/img/ico-qs-03-n.png" class="" height="28" alt="Qsuíte">
                                                            </div>
                                                            <div>
                                                                <br class="d-none d-md-block">
                                                                <h2 class="mb-1 font-weight-medium p-0 title-dash-cliente" style="min-height: 66px;">
                                                                    Documentos
                                                                </h2>
                                                            </div>
                                                            <div class="text-right" style="display: flex; justify-content: space-between;">
                                                                <div class="top-0 font-weight-medium" style="width: fit-content; height: fit-content; font: bold; color:#949494">
                                                                  arquivos: {{ getDocumentQuantityByName('Documentos') }}
                                                                </div>
                                                                <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-6 col-md-4 col-lg-4 p-2 p-sm-2" @click="showModal(5)">
                                                <div style="min-height: 176px;"  class="bg-white h-100 bder-default p-4 mouse-cta-dash">
                                                    <div style="cursor:pointer">
                                                        <div>
                                                            <div class="mb-1 mb-sm-3">
                                                                <img src="@/assets/img/ico-play-n.png" class="" height="28" alt="Qsuíte">
                                                            </div>
                                                            <div>
                                                                <br class="d-none d-md-block">
                                                                <h2 class="mb-1 font-weight-medium p-0 title-dash-cliente" style="min-height: 66px;">
                                                                    YouTube
                                                                </h2>
                                                            </div>
                                                            <div class="text-right" style="display: flex; justify-content: space-between;">
                                                                <div class="top-0 font-weight-medium" style="width: fit-content; height: fit-content; font: bold; color:#949494">
                                                                  arquivos: {{ getDocumentQuantityByName('YouTube') }}
                                                                </div>
                                                                <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- galeria de imagens -->
                                             <div class="" v-for="item, ide in all_enterprises_files" :key="item.id">                                               
                                                <a  :id="'foto'+ide" v-if="showModalDocuments(item.path_name)"  data-fancybox="gallery2" :data-src="item.path_name" :data-thumb="item.path_name" :data-caption="item.description"  :href="item.path_name"  ></a>      
                                            </div>
                                            <!-- <div class="col-6 col-md-4 col-lg-4 p-2 p-sm-2">
                                                <div class="bg-white bder-default p-4 mouse-cta-dash">
                                                    <a href="https://quesuite.com.br/views/landingpage/" target="_blank" title="">
                                                        <div>
                                                            <div class="mb-1 mb-sm-3">
                                                                <img src="@/assets/img/ico-qs-04-n.png" class="" height="28" alt="Qsuíte">
                                                            </div>
                                                            <div>
                                                                <br class="d-none d-md-block">
                                                                <h2 class="mb-1 font-weight-medium p-0 title-dash-cliente">
                                                                    Compartilhar<br>
                                                                    landing page
                                                                </h2>
                                                            </div>
                                                            <div class="text-right">
                                                                <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                          
                                </div>                           
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-3 p-0">                   
                    <div class="col-12 p-2 p-sm-2">
                            <div class="col-12 pt-3 d-flex">
                                <a :href="link_landing_page" v-if="this.$store.getters.getProfile.broker.landingpage_can =='sim'"  class="bg-white w-50 pt-3 mr-3  d-flex align-items-center bder-default justify-content-center pl-2" target="_blank"  style="width: 143px;height: 71px;" >
                                    <p  style="color: var(--color-broker);"> <i class="fa fa-link" aria-hidden="true"></i> Landing Page</p>
                                </a>
                                <div class="bg-white w-50 pt-3 d-flex align-items-center justify-content-center pl-2 bder-default " style="width: 143px;height: 71px; cursor:pointer" >
                                    <div class="dropdown">
                                        <p style="color: var(--color-broker); height: 100%; width: 100%;"  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">  <i class="fa fa-plus"></i> Ações Rápidas</p>
                                        <ul class="dropdown-menu mt-4" aria-labelledby="dropdownMenuButton1">
                                            <li><a class="dropdown-item" href="?app=financeiro.php">Atualizar Cadastro</a></li>
                                            <li> <b-link to="/alterar-senha" class="dropdown-item">Mudar Senha</b-link></li>
                                        </ul>
                                    </div>                             
                                  
                                </div>
                            </div>                          
                    </div>
                    <div class="col-12 p-2 p-sm-2">
                
                        <div class="col-12 pt-3" v-if="tabela.length>=1">
                            <h2 class="mb-2 font-weight-bold title-dash-ar " style="color: var(--color-broker);">Feed Comercial</h2>
                        </div>

                        <div class="col-12 p-0">
                                <div v-if="tabela.length>=1" class="bder-default" v-for="(item, index) in comercials" :key="item.id">
                                 <div v-if="item?.type?.name=='Tabela comercial do mês'">
                                    <a href="#" target="_blank" :d-f="item.path_name">
                                        <div class="d-flex align-self-center bder-default p-3 mt-2"  style="background-color: #fff;">                                           
                                                 <div class="align-self-center pr-3">
                                                    <div v-if="item.enterprise!=null"  class="d-flex align-items-center text-center" :style="`height:60px; width:60px; border-radius:200px; background-color: ${index ? '#fff' : '#f6f6f7'};`">
                                                        <img :src="item.enterprise.image" class="img-fluid p-2" alt="Qsuíte">
                                                    </div>
                                                </div> 
                                                <div class="align-self-center">
                                                    <h5 v-if="item.enterprise!=null" class="mb-0 title-dash-ar-inc"><b>{{item.enterprise.name}}</b></h5>
                                                    <p class="mb-0" style="color:#949494; font-size:14px;" v-if="item.description!=null">{{item.description}}</p>
                                                    <p class="mb-0" style="color:#949494; font-size:12px;" v-else>{{item.path_name}} {{item.created_at}}</p>
                                                </div>
                                               
                                                <!-- <div class="align-self-center ml-auto">
                                                    <img src="@/assets/img/ico-qs-07.png" class="" height="14" alt="Qsuíte">
                                                </div> -->
                                            </div>                                       
                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="video-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" @click="closeModalVideo"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body mx-auto">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/SQufGLAnshw" 
            title="YouTube video player" frameborder="0" 
            allow="" allowfullscreen>
        </iframe>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModalVideo" data-dismiss="modal">Fechar</button>
        </div>
        </div>
    </div>
    </div>
    

        <div id="right-modal" class="modal fade" tabindex="-1" role="dialog" style="display: none;" aria-modal="true">
            <div class="modal-dialog modal-md modal-right">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <button type="button" @click="closeModal" class="close" data-dismiss="modal" aria-hidden="true"><i class="ti-close font-20"></i></button>
                    </div>
                    <div class="modal-body p-0 " style="width: auto; min-height: 100vh;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 py-3 px-4">
                                    <h2 class="mb-1 font-weight-medium font-18">
                                        {{title_modal}}
                                    </h2>
                                </div>

                                <hr class="mb-2" style="height: 1px; background-color:#e8e8e8; width:100%; border:0">

                                <div v-if="!all_enterprises_files.length" >
                                    <div class="w-100 mx-auto d-flex align-self-center">
                                        <div class="align-items-center">
                                            <!-- <Spinner size="80" /> -->
                                        </div>
                                        <div class="align-self-center">
                                            <h3><b class="mb-0 font-15 font-b-4 p-3" style="color:#232323">Sem arquivos disponíveis para esta modalidade</b></h3>
                                        </div>
                                    </div>
                                </div>
                        
                                <template v-if="all_enterprises_files.length">
                                    <div class="d-flex  flex-wrap w-100  pl-2 pr-3" v-for="item in all_enterprises_files" :key="item.id">
                                       
                                         
                                            <!-- <div class="align-self-center">
                                                <h5 class="mb-0 title-dash-ar-inc">{{item.name}}</h5>
                                            </div> -->
                                          
                                            <div class="col-8 mt-2 mb-2">
                                                <h5 class="mb-0 font-16 font-b-5 "> <b>{{item.description}}</b></h5>
                                            </div>
                                   
                                        <div class="row">
                                            <div class="align-self-center ml-auto">
                                                <a v-if="showModalDocuments(item.path_name)" :id="item.id" :data-thumb="item.path_name.split('.').slice(-1).join('') !='jpg' || item.path_name.split('.').slice(-1).join('') !='png' ? item.path_name  :'https://placehold.co/600x400?text=DOCUMENTO'"   data-fancybox="gallery4" :data-src="item.path_name" :data-caption="item.description"  :href="item.path_name">
                                                    <div class="d-flex align-items-center">
                                                        <h5 class="mb-0 font-9 mr-1" v-if="item.path_name.split('.').slice(-1).join('') !='png' && item.path_name.split('.').slice(-1).join('') !='mp4'">Download/.{{item.path_name.split(".").slice(-1).join("")}} </h5>
                                                         <h5 class="mb-0 font-9"  v-if="item.path_name.split('.').slice(-1).join('') =='png'">Download/.{{item.path_name.split(".").slice(-1).join("")}} </h5>
                                                        <h5 class="mb-0 font-9"  v-if="item.path_name.split('.').slice(-1).join('') =='mp4'">Download/.{{item.path_name.split(".").slice(-1).join("")}} </h5>
                                                        <h5 class="mb-0 font-9"  v-if="item.path_name.split('.').slice(-1).join('') =='docx'">Download/.{{item.path_name.split(".").slice(-1).join("")}} </h5>
                                                        <img v-if="item.type.id==3" :src="item.path_name"  width="41"  class="pl-1" height="25">
                                                        <img v-if="item.type.id!==3" src="@/assets/img/baixar.png"  width="31"  class="pl-1 mx-auto" height="25">
                                                        
                                                    </div>
                                                </a>
                                                <!-- <a v-else target="_blank" :id="item.id"  :href="item.path_name"  >
                                                    <div class="d-flex align-items-center">
                                                        <h5 class="mb-0 font-10">Download</h5>
                                                        <img src="@/assets/img/download.png" width="41px"  class="pl-2" height="25">
                                                        
                                                    </div>
                                                </a> -->
                                            </div>
                                            
                                        </div>

                                        <hr class="mt-2 mb-2" style="height: 1px; background-color:#e8e8e8; width:100%; border:0">
                                    </div>
                                    
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        <div id="rightvideo-modal" class="modal fade" tabindex="-1" role="dialog" style="display: none;" aria-modal="true">
            <div class="modal-dialog modal-md modal-right">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <button type="button" @click="closeVideoModal" class="close" data-dismiss="modal" aria-hidden="true"><i class="ti-close font-20"></i></button>
                    </div>
                    <div class="modal-body p-0 " style="width: auto; min-height: 100vh;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 py-3 px-4 pl-2">
                                    <h2 class="mb-1 font-weight-medium pl-2 font-18">
                                        YouTube
                                    </h2>
                                </div>

                                <hr class="m-0" style="height: 1px; background-color:#e8e8e8; width:100%; border:0">

                                <div v-if="!all_enterprises_files.length" >
                                    <div class="w-100 mx-auto d-flex align-self-center">
                                        <div class="align-items-center">
                                            <!-- <Spinner size="80" /> -->
                                        </div>
                                        <div class="align-self-center">
                                            <h3><b class="mb-0 font-15 font-b-4 p-4" style="color:#232323">Sem arquivos disponíveis para esta modalidade</b></h3>
                                        </div>
                                    </div>
                                </div>
                        
                                <template v-if="all_enterprises_files.length">
                                    <div class="d-flex  flex-wrap w-100 ml-3 pl-2" v-for="item, ide in all_enterprises_files" :key="item.id">
                                        <div class="d-flex w-100 align-self-center py-1 mr-3" >
                                         
                                            <div class="align-self-center">
                                                <h5 class="mb-0 title-dash-ar-inc">{{item.name}}</h5>
                                            </div>

                                            
                                          
                                            <div class="d-flex w-100" style="cursor:pointer">
                                                <div class="col-9 mt-2 p-0 mb-2 pl-2">
                                                    <h5 class="mb-0 font-16 font-b-5"><b v-if="item.description != null">{{item.description}}</b><b v-if="item.description == null">{{item.path_name}}</b></h5> 
                                                </div>
                                                 <div @click="abrirVideo(ide)" class="d-flex mt-2">
                                                <b  class="mb-0 font-9 mr-2" >ASSISTIR</b>
                                                    <b width="40px" 
                                                   >▶</b>
                                                </div>
                                            
                                            </div>
                                        </div>
                                        <div class="" v-for="item, ide in all_enterprises_files" :key="item.id">                                               
                                                <a :id="'video'+ide" class="fancybox_video fancybox.iframe"  data-fancybox="gallery3" :data-src="item.path_name" :data-caption="item.description"  :href="item.path_name"></a>
                                        </div>


                            
                                        <div class="col-12 px-0 text-right d-flex">
                                            <div class="align-self-center ml-auto">
                                                <!-- <a v-if="showModalDocuments(item.path_name)" :id="item.id" :data-thumb="item.path_name ?? 'https://placehold.co/600x400?text=DOCUMENTO'"   data-fancybox="gallery" :data-src="item.path_name" :data-caption="item.description"  :href="item.path_name">
                                                    <div class="d-flex align-items-center">
                                                        <h5 class="mb-0 font-10">Download</h5>
                                                        <img v-if="item.type.id==3" :src="item.path_name"  width="41px"  class="pl-2" height="25">
                                                      <img v-if="item.type.id!==3" src="@/assets/img/download.png"  width="41px"  class="pl-2" height="25">
                                                        
                                                    </div>
                                                </a> -->
                                                <!-- <a v-else target="_blank" :id="item.id"  :href="item.path_name"  >
                                                    <div class="d-flex align-items-center">
                                                        <h5 class="mb-0 font-10">Download</h5>
                                                        <img src="@/assets/img/download.png" width="41px"  class="pl-2" height="25">
                                                        
                                                    </div>
                                                </a> -->
                                            </div>
                                            
                                        </div>

                                        <hr class="m-0" style="height: 1px; background-color:#e8e8e8; width:100%; border:0">
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                 
        </div>
          
        
        
        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen" style="max-width: 100%; height: 100%;">
                <div class="modal-content" style="min-height: 100%;">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">{{videodescription}}</h5>
                    <button type="button" class="btn-close btn btn -primary" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body d-inline-flex">
                         <iframe width="100%" :src="video" 
                        title="YouTube video player" frameborder="0" 
                        allow="" allowfullscreen>
                    </iframe>
                </div>
                </div>
            </div>
        </div>  

        <div class="col-9 w-100 mx-auto p-2 p-sm-2">
            <div class="col-12 py-2 text-center">
                <h3 class="font-28 font-b-6 mb-1" style="color: var(--color-broker);">{{theme.sentence}}</h3>
            </div>
            <div v-if="theme.image_desktop" class="d-none d-md-block pr-0 ">
                <img :src="theme.image_desktop" class="mx-auto d-flex  bder-default bder-default" title="Banner2">
            </div>
            <div v-if="(theme.image_mobile)" class="d-md-none d-flex">
                <img :src="theme.image_mobile" class="img-fluid bder-default" title="Banner2">
            </div>
        </div>
       <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a :href="'https://portaldocorretor.'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de uso</a> | <a :href="'https://portaldocorretor.'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> | <a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <a href="https://qsuite.com.br" target="_blank"><img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>

</div>

</template>
<script>

import ThemeService from "@/services/resources/ThemeService";
import EnterpriseFileBrokerService from "@/services/resources/EnterpriseFileBrokerService";
import EnterpriseFileService from "@/services/resources/EnterpriseFileService";
import EnterpriseFileCountService from "@/services/resources/EnterpriseFileCountService";
import Spinner from "../../components/Spinner.vue";

const serviceEnterpriseFile = EnterpriseFileService.build();
const serviceEnterpriseCountFile = EnterpriseFileCountService.build();
const serviceTheme = ThemeService.build();
const serviceEnterpriseBroker = EnterpriseFileBrokerService.build();

  export default {
    name: "Dashboard",

    components: {
       Spinner
    },
    data() {
        return {
             site:"",
             carregando:"não",
             carregandoarquivo:"não",
             comercials: [],
             videodescription: "",
             video: "",
             documentoquantidade: 0,
             kitsquantidade : 0,
             imagemquantidade: 0,
             tabela: [],
             kitquantidade: 0,
             tabelaquantidade: 0,
             videoquantidade: 0,
            all_enterprises: [],
            carregando_imagens: "não",
            enterprise: {},
            name: null,
            link_landing_page: "",
            companyId:null,
            enterprise_id: this.$route.params.id,
            theme: {
                sentence: null,
                image_desktop: null,
                image_mobile: null
            },
            showSpinner: true,
            documents: [],
            all_enterprises_files: [],
            title_modal: '',
            all_documents: [],
            documentsQuantity: null,
        };
    },
    watch: {
        enterprise_id() {
            serviceEnterpriseBroker 
            .search({enterprise_id: this.enterprise_id})
            .then(resp => {
                this.enterprise = resp[0];
                this.enterprise.link_youtube = "https://www.youtube.com/watch?v=SQufGLAnshw"
            }); 
        }
      
    },
   
    methods: {
        getDocumentQuantityByName(name) {
          const foundObject = this.documentsQuantity.find(obj => obj.name === name);
          return foundObject ? foundObject.count : 0;
        },
        getDocumentsQuantity(companyId)
        {
          
          serviceEnterpriseCountFile
            .get(`/company/${companyId}/enterprise-file/${this.enterprise_id}/quantity/files`)
            .then(resp => {
               let documentsCount = resp;
               this.documentsQuantity = documentsCount;
            })

            console.log('document-quantity',this.documentsQuantity)
        },
        showModalDocuments(path) {
            path = path.split('.');
            let extension = path[path.length -1];

            return (
                extension === 'pdf' || extension === 'jpg' || extension === 'jpeg' || extension === 'png' ||  extension === 'gif' || 
                extension === 'bmp' || extension === 'webp'|| extension == 'mp4' || extension == 'wmv'
            );

        },
        getMonth(index) {
            let months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ', ]
            return months[index - 1];
        },
        getDocuments(type_id = null) {
            // comentario para teste de branch
            let text = '';
            switch(type_id) {
                case 1:
                    text = 'Tabela Comercial do Mês';
                    break;
                case 2:
                    text = 'Kit Marketing';
                    break;
                case 3:
                    text = 'Imagens e Plantas';
                    break;
                case 4:
                    text = `Documentos ${this.enterprise.name}`;
                break;
            }

            this.title_modal = text;

            let data = {
                enterprise_id: this.enterprise_id,
                enterprises_files_types_id: type_id,
                all: 1
            };
            
            if(type_id!=null && type_id==3){
                this.carregando_imagens='sim'
            }
            if(type_id!==3){
             this.carregandoarquivo="sim"
            }

        //  serviceEnterpriseFile.search({enterprise_id: this.enterprise_id, all:1})
        //     .then(resp => {
        //         let createdAtTreated;
               
            //    this.carregandoarquivo="não"
            //     this.tabelaquantidade = resp.filter(function(ele) {
            //         if(ele.type.name=="Tabela comercial do mês"){
            //             return ele
            //         }
            //     }).length
            //     this.documentoquantidade = resp.filter(function(ele) {
            //         if(ele.type.name=="Documentos"){
            //             return ele
            //         }
            //     }).length
            //     this.imagemquantidade = resp.filter(function(ele) {
            //         if(ele.type.name=="Imagens e plantas"){
            //             return ele
            //         }
            //     }).length
            //     this.kitquantidade = resp.filter(function(ele) {
            //         if(ele.type.name=="Kit marketing"){
            //             return ele
            //         }
            //     }).length
            //     this.videoquantidade = resp.filter(function(ele) {
            //         if(ele.type.name=="Videos"){
            //             return ele
            //         }
            //     }).length
            // })


            serviceEnterpriseFile.search(type_id ? data : { enterprise_id: this.enterprise_id, all:1} )
            .then(resp => {
                let createdAtTreated;

                if(!type_id) {
                   
                    let all_documents_treated = ""
                    if(resp.data !=null){
                        all_documents_treated = resp.data.map(item => {
                        createdAtTreated = item.created_at.split('T')[0];

                        let month = createdAtTreated.split('-')[1];
                        let year = createdAtTreated.split('-')[0];

                        return {
                            ...item,
                            created_at: `${this.getMonth(month)}/${year[year.length-1]}${year[year.length-2]}`
                        }
                    });
                    } else {
                         all_documents_treated = resp.map(item => {
                        createdAtTreated = item.created_at.split('T')[0];
                        let month = createdAtTreated.split('-')[1];
                        let year = createdAtTreated.split('-')[0];

                        return {
                            ...item,
                            created_at: `${this.getMonth(month)}/${year[year.length-1]}${year[year.length-2]}`
                        }
                    });
                    }

                    this.all_documents = all_documents_treated.slice(0, 3);
                    this.tabela=this.all_documents.filter(function(ele) {
                      if(ele?.type?.name == 'Tabela comercial do mês') {
                         return ele
                      }                     
                    })
                    console.log(this.tabela);
                } else {
                    if(type_id==3){
                            this.carregando_imagens='não'
                     }
                    this.all_enterprises_files = resp.map(item => {
                            
                            createdAtTreated = item.created_at.split('T')[0];
                            
                            createdAtTreated = createdAtTreated.split('-');
                            createdAtTreated = `${createdAtTreated[2]}/${createdAtTreated[1]}/${createdAtTreated[0]}`
                            createdAtTreated = `${createdAtTreated} | ${item.created_at.split('T')[1].slice(0, 8)}`
                            return {
                                ...item,
                                created_at: createdAtTreated,
                            }
                        });

                        if(type_id==3){
                            this.$nextTick(function(){
                                setTimeout(() => {
                                    
                               
                              if(document.getElementById('foto0')!=null) {
                                   document.getElementById('foto0').click()
                                }
                                 }, 500);
                            })                                            

                        }
            
                        if(type_id==5){    
                            setTimeout(() => {
                                jQuery("#rightvideo-modal").modal("show");
                            }, 301);
                        }

                        if(type_id!=3 && type_id!=5){    
            
                            //     var modal = new bootstrap.Modal(document.getElementById('right-modal'), {
                            //       keyboard: false
                            //     })
                            //     var modalToggle = document.getElementById('toggleMyModal') // relatedTarget
                                
                            //    setTimeout(() => {
                            //         modal.show(modalToggle)
                                
                            //      },301)
                                            
                
                            setTimeout(function() {
                                jQuery("#right-modal").modal("show");
                            }, 301);                   
                    }


                }


               
            })

        },
        abrirVideo(ide) {
           
            this.$nextTick(function(){
                document.getElementById('video'+ide).click()
            })                                            

            
        },
        pegaFeed() {
               serviceEnterpriseFile.search({  enterprises_files_types_id: 1,enterprise_id: this.enterprise_id, all:1 } )
            .then(resp => {
                let createdAtTreated;                
                          
            this.comercials= resp.map(item => {
                    
                    createdAtTreated = item.created_at.split('T')[0];
                    
                    createdAtTreated = createdAtTreated.split('-');
                    createdAtTreated = `${createdAtTreated[2]}/${createdAtTreated[1]}/${createdAtTreated[0]}`
                    createdAtTreated = `${createdAtTreated} | ${item.created_at.split('T')[1].slice(0, 8)}`
                    return {
                        ...item,
                        created_at: createdAtTreated,
                    }
                });

                
                
               
            })
        },
        closeModal() {
            this.enterpriseSelected = null;
            jQuery("#right-modal").modal("hide");
        },
        closeVideoModal() {
            this.enterpriseSelected = null;
            jQuery("#rightvideo-modal").modal("hide");
        },
        showModal(type_id) {


           this.getDocuments(type_id);

         
        },

        // showModalVideo() {
        //     jQuery("#video-modal").modal("show");          
        // },

        closeModalVideo() {
            jQuery("#video-modal").modal("hide");
        },

        fetchEnterprise() {
            this.carregando="sim"
            serviceEnterpriseBroker 
            .search({ enterprise_id: this.enterprise_id})
            .then(resp => {
                this.carregando="não"
                this.enterprise = resp[0];
            }); 
        },
        filterByName() {

            this.fetchEnterprises({name: this.name});
        },

        fetchTheme(){
           
           let data = {
               domain: window.location.hostname
           };

           serviceTheme 
           .search(data)
           .then(resp => {
         
               resp.forEach((element, index) => {
                   switch(element.key){
                        case 'PORTAL_BROKER_BACKGROUND_DESKTOP_LOGGED':
                            this.theme.image_desktop = element.value;
                        break;
                        case 'PORTAL_BROKER_BACKGROUND_MOBILE_LOGGED':
                            this.theme.image_mobile = element.value;
                        break;
                        case 'PORTAL_BROKER_SENTENCE_LOGGED':

                        this.theme.sentence = element.value[0].toUpperCase() + element.value.substr(1)
                            break;
                      
                   }
               });
               
           })
          
       },
        openModalVideo(description, video) {
            this.videodescription=description
            this.video=video
        },
       fetchEnterprises(){
          
           let data = {
               all: 1
           };

           serviceEnterpriseBroker
                .search(data)
                .then(resp => {
                    this.all_enterprises = resp;
                });
       },

    },
   
    mounted() {
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        this.site=window.location.host
       // this.fetchEnterprises();
        this.fetchTheme();
        this.fetchEnterprise();
        this.getDocuments();
        this.getDocumentsQuantity(this.$store.getters.getProfile.company_id);
        Fancybox.bind('[data-fancybox="gallery"]', {
        //
        });    

        this.pegaFeed()

        console.log(this.$store.getters.getProfile.first_name);
        console.log(this.$store.getters.getProfile.last_name);

        let first_name = ""
        let last_name =""

        let first_names = this.$store.getters.getProfile.first_name.split(" ");
  
        for(var i =0; i < first_names.length; i++){
            if (i == first_names.length) { 
                first_name+=first_names[i]
            } else {
                first_name+=first_names[i]+"-"
            }
        }

        let last_names = this.$store.getters.getProfile.last_name.split(" ");
  
        for(var i =0; i < last_names.length; i++){
            if (i == last_names.length) { 
                last_name+=last_names[i]
            } else {
                last_name+=last_names[i]+"-"
            }
        }
        console.log(this.$store.getters.getProfile);
        this.link_landing_page = "https://"+this.site+"/corretor/"+first_names.toString().toLowerCase()+"-"+last_names.toString().toLowerCase()+"-"+ this.$store.getters.getProfile.broker.creci


        
        $(document).ready(() => {
            $('.selectsearch').select2({
                "language":{
                    "noResults" : function () { return 'Busca não encontrada'; }
                }                                    
            }); 
            $(".selectsearch").val(this.$data.enterprise_id)
        });

        $(".selectsearch").on("change", ()=> {            
            this.$data.enterprise_id = parseInt($(".selectsearch").val())
        }); 

        $(function () {
            if ($(".modal-autoheight").length > 0) {
                $(".modal").on("show.bs.modal", () => {
                    var winHeight = $(window).height();
                    $(".modal-autoheight .modal-body").css({
                        width: "auto",
                        height: (winHeight - 0) + "px"
                    });
                });
                $(window).on("resize", () => {
                    var winHeight = $(window).height();
                    $(".modal-autoheight .modal-body").css({
                        width: "auto",
                        height: (winHeight - 0) + "px"
                    });
                });
            }
        });
        
    },
    computed: {
        user() {
            return this.$store.getters.getProfile;
        },
        renderElements() {
            
        }
    },
    components: {}
}


</script>